import type { Outreach } from 'clients/OutreachTemplateclient/OutreachTemplateClient.types';
import type { IndeedProject } from 'shared/hooks/useBrowserExtension/indeed/getProjectsList';
import type { OutreachPanelPageInfo } from './OutreachModalContext/OutreachModalContext';

import { FloatingPortal } from '@floating-ui/react';
import {
  Button,
  Dialog,
  Loader,
  OutreachEditorRef,
  OutreachEditorType,
} from '@recruitrobin/robin-theme/web-components';
import { CheckCircleBroken, XCircle } from '@recruitrobin/robin-theme/web-icons';
import { motion } from 'framer-motion';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { useWaitBeforeRemove } from 'shared/contexts/CandidateContext/actions/useWaitBeforeRemove';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { SnackbarContext } from 'shared/contexts/SnackbarContext/SnackbarContext';
import { useUserEmailSignatureQuery } from 'shared/hooks/useUserEmailSignatureQuery';
import { OutreachPanelPage } from '../OutreachPanel/utils/OutreachPanelPage';
import { useStyles } from './OutreachModal.styles';
import { OutreachModalProps } from './OutreachModal.types';
import { OutreachModalContext, OutreachModalContextState } from './OutreachModalContext';
import { initialState } from './OutreachModalContext/OutreachModalContext';
import { OutreachPanelLazy } from './OutreachPanel.lazy';

const WIDTH_EXPANDED_TOOLBAR = 651;

export const OutreachModal = forwardRef<HTMLDivElement, OutreachModalProps>(
  ({ candidate, type, onRequestCloseModal, onRequestChangeType, actions, currentUser, tokens }, ref) => {
    const { createSnackbar } = useContext(SnackbarContext);
    const { dictionary } = useContext(LocalizationContext);
    const classes = useStyles();
    const [page, setPage] = useState(initialState.page);
    const [pageInfo, setPageInfo] = useState<OutreachPanelPageInfo>(initialState.pageInfo);
    const [selectedTemplate, setSelectedTemplate] = useState<Outreach | null>(initialState.selectedTemplate);
    const [selectedIndeedProject, setSelectedIndeedProject] = useState<IndeedProject | null>(
      initialState.selectedIndeedProject,
    );
    const { createWaitBeforeRemove, resolveLastWait } = useWaitBeforeRemove();
    const [editorSubjectRef, setEditorSubjectRef] = useState<OutreachEditorRef | null>(null);
    const [editorMessageRef, setEditorMessageRef] = useState<OutreachEditorRef | null>(null);

    const [showWhatsappWebModal, setShowWhatsappWebModal] = useState(false);
    const [isSendingWhatsappMessage, setIsSendingWhatsappMessage] = useState(false);

    const emailSignature = useUserEmailSignatureQuery({ enabled: type === OutreachEditorType.Email });

    useEffect(() => {
      return () => {
        setPage(initialState.page);
        setPageInfo(initialState.pageInfo);
        setSelectedTemplate(initialState.selectedTemplate);
        setSelectedIndeedProject(initialState.selectedIndeedProject);
        resolveLastWait({ removeFromList: true });
      };
    }, [type]);

    useEffect(() => {
      setPageInfo((pageInfo) => ({ ...pageInfo, outreach: { ...pageInfo.outreach, werknl: currentUser } }));
    }, [currentUser]);

    const onContact = async () => {
      let subject = '';
      let message = '';

      if (editorSubjectRef) {
        switch (type) {
          case OutreachEditorType.Email:
          case OutreachEditorType.LinkedinInmail:
          case OutreachEditorType.Indeed:
            subject = editorSubjectRef.getTextContent({ replaceTokens: true });
        }
      }

      if (editorMessageRef) {
        switch (type) {
          case OutreachEditorType.Email:
            message = editorMessageRef.serializeToHTML({ replaceTokens: true });
            break;

          case OutreachEditorType.Phone:
          case OutreachEditorType.Indeed:
          case OutreachEditorType.Linkedin:
          case OutreachEditorType.Werknl:
          case OutreachEditorType.Whatsapp:
          case OutreachEditorType.LinkedinInmail:
            message = editorMessageRef.getTextContent({ replaceTokens: true });
        }
      }

      switch (type) {
        case OutreachEditorType.Phone:
          await Promise.all([
            actions.onSaveNotes(message),
            actions.onContact({}, 'call', {}, { waitBeforeRemoveFromList: createWaitBeforeRemove() }),
          ]);

          setPage(OutreachPanelPage.Labels);
          setPageInfo((pageInfo) => ({ ...pageInfo, contact: 'success' }));

          break;

        case OutreachEditorType.Whatsapp:
          return setShowWhatsappWebModal(true);

        case OutreachEditorType.Linkedin:
          return actions.onContact({ body: message }, 'linkedin').then(() => onRequestCloseModal());

        case OutreachEditorType.LinkedinInmail:
          return actions.onContact({ body: message }, 'linkedin_inmail').then(() => onRequestCloseModal());

        case OutreachEditorType.Email:
          return actions
            .onContact({ subject, body: message, signOff: emailSignature.data?.signature_html }, 'email')
            .then(() => onRequestCloseModal());

        case OutreachEditorType.Indeed:
          return actions
            .onContact({ subject, body: message }, 'indeed', {
              indeedProjectId: selectedIndeedProject?.key,
            })
            .then(() => onRequestCloseModal());

        case OutreachEditorType.Werknl:
          return actions
            .onContact(
              {
                subject,
                body: message,
                email: pageInfo.outreach.werknl.email,
                phoneNumber: pageInfo.outreach.werknl.phone,
                website: pageInfo.outreach.werknl.website,
              },
              'werknl',
            )
            .then(() => onRequestCloseModal());
      }
    };

    const value = {
      refs: {
        subject: editorSubjectRef,
        message: editorMessageRef,
        setMessage: setEditorMessageRef,
        setSubject: setEditorSubjectRef,
      },
      selectedTemplate,
      setSelectedTemplate,
      selectedIndeedProject,
      setSelectedIndeedProject,
      candidate,
      type,
      page,
      setPage,
      onRequestCloseModal,
      onRequestChangeType,
      onContactSuccesful: onContact,
      onContactUnsuccessful: async () => {
        const message = editorMessageRef?.getTextContent();

        if (message) {
          await actions.onSaveNotes(message);
        }

        setPage(OutreachPanelPage.Labels);
        setPageInfo((pageInfo) => ({ ...pageInfo, contact: 'unsuccess' }));
      },
      pageInfo,
      setPageInfo,
      onSendMessage: () =>
        onContact()
          .then(() => {
            createSnackbar(dictionary.success, { variant: 'success' });
          })
          .catch(() => {
            createSnackbar(dictionary.error, { variant: 'danger' });
          }),
      tokens,
      actions,
    } satisfies OutreachModalContextState;

    return (
      <>
        <OutreachModalContext.Provider value={value}>
          <FloatingPortal>
            <motion.div
              ref={ref}
              css={classes.root}
              initial={{ width: 0 }}
              animate={{ width: WIDTH_EXPANDED_TOOLBAR }}
              exit={{ width: 0 }}
              transition={{ duration: 0.24, ease: [0.22, 0.9, 0.53, 1] }}>
              <motion.div
                key={type}
                css={classes.fadeInOut}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.24, ease: [0.22, 0.9, 0.53, 1] }}>
                <OutreachPanelLazy />
              </motion.div>
            </motion.div>
          </FloatingPortal>
        </OutreachModalContext.Provider>
        <Dialog
          maxWidth={600}
          isVisible={showWhatsappWebModal}
          title={dictionary.confirmMessageTitleModal}
          body={dictionary.confirmMessageDescriptionModal}
          renderButtons={
            <>
              <Button
                label={dictionary.messageNotSent}
                onClick={() => setShowWhatsappWebModal(false)}
                style="filled"
                type="warning"
                startIcon={XCircle}
              />
              <Button
                label={dictionary.messageSent}
                onClick={() => {
                  setIsSendingWhatsappMessage(true);
                  const message = editorMessageRef?.getTextContent();

                  actions.onContact({ body: message }, 'whatsapp').finally(() => {
                    setIsSendingWhatsappMessage(false);
                    setShowWhatsappWebModal(false);
                    onRequestCloseModal();
                  });
                }}
                style="filled"
                type="highlight"
                startIcon={
                  isSendingWhatsappMessage ? <Loader size={18} color={(c) => c.primary.fuchsia} /> : CheckCircleBroken
                }
              />
            </>
          }
        />
      </>
    );
  },
);
