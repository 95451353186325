export enum QueryKey {
  campaignManagerContextUseCampaigns = 'campaignManagerContextUseCampaigns',
  selectedCampaignContextUseCandidates = 'selectedCampaignContextUseCandidates',
  selectedCampaignContextUseStats = 'selectedCampaignContextUseStats',
  selectedCampaignContextUseFacets = 'selectedCampaignContextUseFacets',
  sharedCandidateContextUseCandidate = 'sharedCandidateContextUseCandidate',
  selectedCampaignContextUseCandidateMatches = 'selectedCampaignContextUseCandidateMatches',
  useCandidateActivityLogQuery = 'useCandidateActivityLogQuery',
  useCandidateHistoryQuery = 'useCandidateHistoryQuery',
  frontend2beCandidate = 'frontend2beCandidate',
  connectToAtsUseAts = 'connectToAtsUseAts',
  campaignManagerContextUseCampaign = 'campaignManagerContextUseCampaign',
  selectedCandidateLoadNotes = 'selectedCandidateLoadNotes',
  useTenantDistinctedLabelsQuery = 'useTenantDistinctedLabelsQuery',
  useRequestCv = 'useRequestCv',
  sharedPageMatches = 'sharedPageMatches',
  useOutreachTemplateByTypeQuery = 'useOutreachTemplateByTypeQuery',
  useUserEmailSignatureQuery = 'useUserEmailSignatureQuery',
  useCurrentUserQuery = 'useCurrentUserQuery',
  useGetCampaignNewRepliesQuery = 'useGetCampaignNewRepliesQuery',
}
