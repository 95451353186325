import { useQuery } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { QueryKey } from 'types/query';

export const useGetCampaignNewRepliesQuery = () => {
  return useQuery(
    [QueryKey.useGetCampaignNewRepliesQuery],
    async () => {
      const { data } = await campaignsClient.getCampaignNewReplies();

      return data.new_replies;
    },
    {
      refetchInterval: 60_000,
      initialData: 0,
      initialDataUpdatedAt: 0,
    },
  );
};
