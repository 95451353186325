/* eslint-disable @typescript-eslint/no-unused-vars*/
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import {
  IndeedResponse as IndeedCommonModelResponse,
  LinkedinCreditsResponse,
  SendIndeedMessageParams,
  SendLinkedinConnectionRequestParams,
  SendLinkedinProMessageParams,
  UseBrowserExtension,
  BrowserExtensionInfo,
  BrowserExtensionInfoStatus,
  type SendLinkedinMessageParams,
  type SendLinkedinMessageResponse,
  ExistsConversationWithCandidateResponse,
  SendLinkedinMessageProps,
  ExistsConversationWithCandidateResult,
} from './types';
import { IndeedClient } from './indeed';
import { useGTM } from 'hooks/gtm';
import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { isObject } from 'helpers/helpers';
import { getStringBinaryUUID } from 'helpers/generateBinaryUUID';

export const testUrls = {
  linkedin: 'https://www.linkedin.com/voyager/api/identity/profiles/me/profileView',
  indeedToken: 'https://employers.indeed.com/tools',
  linkedinPro: 'https://www.linkedin.com/talent/api/talentRecruiterTags?count=100',
};

export const creditUrls = {
  linkedin:
    'https://www.linkedin.com/voyager/api/graphql?variables=()&queryId=voyagerRelationshipsDashCustomInviteComposeView.853d72a59b13b61229e9a90d2a42317b',
  linkedinPro:
    'https://www.linkedin.com/talent/api/talentMessageComposeInfo?decoration=%28inMailCreditInfo%28creditBalance%2CnextRefreshDate%29%29',
  indeed: 'https://resumes.indeed.com/api/common-model',
};

const cacheTime = 60 * 1000;

let statusSent = false;

export const useBrowserExtension = (): UseBrowserExtension => {
  const indeedClient = useRef(new IndeedClient());
  const gtm = useGTM();

  const { data: info = {} } = useQuery<Partial<BrowserExtensionInfo>, unknown, Partial<BrowserExtensionInfo>, string[]>(
    ['getExtensionStatus'],
    ({ queryKey: [queryKey] }) => browserExtensionClient.sendMessage(queryKey),
    { cacheTime, staleTime: cacheTime },
  );

  useEffect(() => {
    if (statusSent === false && info?.status === BrowserExtensionInfoStatus.Active) {
      statusSent = true;
      gtm.browserExtensionActivated();
    }
  }, [info?.status]);

  const { data: { status: linkedinStatus = null } = {} } = useQuery(
    ['proxyHttpRequest', { url: testUrls.linkedin, addHeadersFrom: 'linkedInClassic' }] as const,
    ({ queryKey: [queryKey, params] }) => browserExtensionClient.sendMessage(queryKey, params),
    { enabled: info?.status === BrowserExtensionInfoStatus.Active, cacheTime, staleTime: cacheTime },
  );

  const { data: { status: linkedinProStatus = null } = {} } = useQuery(
    [
      'proxyHttpRequest',
      {
        url: testUrls.linkedinPro,
        addHeadersFrom: 'linkedInClassic',
        headers: {
          'content-type': 'application/json; charset=UTF-8',
        },
      },
    ] as const,
    ({ queryKey: [queryKey, params] }) => browserExtensionClient.sendMessage(queryKey, params),
    { enabled: info?.status === BrowserExtensionInfoStatus.Active, cacheTime, staleTime: cacheTime },
  );

  const { data: { indeedCreditBalance, indeedStatus = null } = {} } = useQuery(
    ['userPlatformCredits', { url: creditUrls.indeed }] as const,
    async () => {
      const { contactsRemaining: creditBalance, subscriptionAssigned } = await getIndeedInfo();

      return { indeedCreditBalance: creditBalance, indeedStatus: subscriptionAssigned ? 200 : 401 };
    },
    { enabled: info?.status === BrowserExtensionInfoStatus.Active, cacheTime, staleTime: cacheTime },
  );

  const { data: { body: indeedTokenBody = null } = {} } = useQuery(
    ['proxyHttpRequest', { url: testUrls.indeedToken }] as const,
    ({ queryKey: [queryKey, params] }) => browserExtensionClient.sendMessage(queryKey, params),
    { enabled: info?.status === BrowserExtensionInfoStatus.Active, cacheTime, staleTime: cacheTime, retry: 0 },
  );

  const { data: { linkedinCreditBalance } = {} } = useQuery(
    ['userPlatformCredits', { url: creditUrls.linkedinPro }] as const,
    async () => {
      const creditBalance = await getLinkedInCredits();

      return { linkedinCreditBalance: creditBalance };
    },
    { enabled: info?.status === BrowserExtensionInfoStatus.Active, cacheTime, staleTime: cacheTime },
  );

  const indeedToken: string | null = useMemo(() => {
    if (indeedTokenBody && indeedStatus === 200) {
      const regexR = indeedTokenBody.match(/"csrf":"(.*?)"/) ?? [];

      return regexR[1] || null;
    }

    return null;
  }, [indeedTokenBody, indeedStatus]);

  useEffect(() => {
    if (indeedToken) {
      indeedClient.current.setCtk(indeedToken);
    }
  }, [indeedToken]);

  const { data: indeedProjects } = useQuery(
    ['indeedProjectsList'],
    async () => {
      const response = await indeedClient.current.getProjectList();

      return response.findTalentProjectBriefs.projects;
    },
    { enabled: !!indeedToken, cacheTime, staleTime: cacheTime, initialData: [], initialDataUpdatedAt: 0 },
  );

  const { data: indeedJobs } = useQuery(
    ['indeedJobsList'],
    async () => {
      const response = await indeedClient.current.getJobsList();

      return response.dradisJobModels ?? [];
    },
    { enabled: !!indeedToken, cacheTime, staleTime: cacheTime, initialData: [], initialDataUpdatedAt: 0 },
  );

  const hasLinkedinFreeInviteRemaining = useCallback(async () => {
    const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
      url: creditUrls.linkedin,
      method: 'GET',
      addHeadersFrom: 'linkedInClassic',
    });

    const data = JSON.parse(response.body);

    if (typeof data === 'object') {
      const elements: unknown[] = data.data?.relationshipsDashCustomInviteComposeViewByInviter?.elements || [];

      const runOutInvites = elements.some(
        (element) => isObject(element) && isObject(element.creditLimitReachedPremiumUpsellSlot),
      );

      if (runOutInvites) {
        return false;
      }
    }

    return true;
  }, []);

  window.hasLinkedinFreeInviteRemaining = hasLinkedinFreeInviteRemaining;

  const sendLinkedinConnectionRequest = async (data: SendLinkedinConnectionRequestParams): Promise<any> => {
    return browserExtensionClient.sendMessage('proxyHttpRequest', {
      url: 'https://www.linkedin.com/voyager/api/voyagerRelationshipsDashMemberRelationships?action=verifyQuotaAndCreate',
      method: 'POST',
      body: JSON.stringify(data),
      addHeadersFrom: 'linkedInClassic',
    });
  };

  const getLinkedinIdByUser = async () => {
    const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
      url: testUrls.linkedin,
      method: 'GET',
      addHeadersFrom: 'linkedInClassic',
    });

    const body: { entityUrn: string } = JSON.parse(response.body);

    if (!body.entityUrn) {
      return null;
    }

    const linkedinId = body.entityUrn.split(':fs_profileView:')[1];

    return linkedinId;
  };

  const sendLinkedinMessage = async ({
    text,
    candidateConversation,
  }: SendLinkedinMessageProps): Promise<SendLinkedinMessageResponse> => {
    const binaryString = getStringBinaryUUID();

    const linkedinUserId = await getLinkedinIdByUser();

    if (!linkedinUserId) {
      throw new Error('Error while trying to get linkedin user, please try again in a few seconds');
    }

    const body: SendLinkedinMessageParams = {
      ...(candidateConversation.isFirstConversation && {
        hostRecipientUrns: [`urn:li:fsd_profile:${candidateConversation.candidateId}`],
      }),
      message: {
        body: {
          text,
        },
        renderContentUnions: [],
        ...(!candidateConversation.isFirstConversation && {
          conversationUrn: `urn:li:msg_conversation:(urn:li:fsd_profile:${linkedinUserId},${candidateConversation.threadId})`,
        }),
      },
      mailboxUrn: `urn:li:fsd_profile:${linkedinUserId}`,
      trackingId: binaryString,
      dedupeByClientGeneratedToken: false,
    };

    return browserExtensionClient.sendMessage('proxyHttpRequest', {
      url: 'https://www.linkedin.com/voyager/api/voyagerMessagingDashMessengerMessages?action=createMessage',
      method: 'POST',
      body: JSON.stringify(body),
      addHeadersFrom: 'linkedInClassic',
    });
  };

  const existsConversationWithCandidate = async (
    candidateId: string,
  ): Promise<ExistsConversationWithCandidateResult | undefined> => {
    const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
      url: `https://www.linkedin.com/voyager/api/voyagerMessagingDashComposeOptions/urn%3Ali%3Afsd_composeOption%3A(${candidateId}%2CNONE%2CEMPTY_CONTEXT_ENTITY_URN)`,
      method: 'GET',
      addHeadersFrom: 'linkedInClassic',
    });

    const result: ExistsConversationWithCandidateResponse = JSON.parse(response.body);

    if (result.composeNavigationContext.existingConversationUrn) {
      return {
        threadId: result.composeNavigationContext.existingConversationUrn.split(':fsd_conversation:')[1],
        isFirstConversation: false,
      };
    }

    if (result.composeOptionType === 'CONNECTION_MESSAGE') {
      return { candidateId, isFirstConversation: true };
    }

    return undefined;
  };

  const sendLinkedinProMessage = ({
    subject,
    body,
    signature,
    candidate_id,
  }: SendLinkedinProMessageParams): Promise<any> => {
    const data = {
      elements: [
        {
          subject,
          body,
          signature,
          postOption: {
            value: {
              'com.linkedin.talent.message.InMailPost': {
                recipient: `urn:li:ts_profile:${candidate_id}`,
              },
            },
          },
        },
      ],
    };

    return browserExtensionClient.sendMessage('proxyHttpRequest', {
      url: 'https://www.linkedin.com/talent/api/talentMessagePosts',
      method: 'POST',
      body: JSON.stringify(data),
      addHeadersFrom: 'linkedInClassic',
      headers: {
        'content-type': 'application/json; charset=UTF-8',
        'x-restli-method': 'BATCH_CREATE',
      },
    });
  };

  const createIndeedProject = (name: string) => {
    return indeedClient.current.createProject(name);
  };

  const sendIndeedMessage = (data: SendIndeedMessageParams) => {
    return indeedClient.current.sendMessage({
      candidateId: data.accountKeys[0],
      description: data.description,
      jobTitle: data.jobTitle,
      message: data.message,
      senderName: data.senderName,
      senderCompany: data.senderCompany,
      projectKey: data.projectKey,
    });
  };

  const getLinkedInCredits = async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
        url: creditUrls.linkedinPro,
        method: 'GET',
        addHeadersFrom: 'linkedInClassic',
      });

      const linkedinCreditsResponse: LinkedinCreditsResponse = JSON.parse(response.body);
      const creditBalance = linkedinCreditsResponse?.inMailCreditInfo?.creditBalance ?? 0;

      return creditBalance;
    } catch (e) {
      throw e;
    }
  };

  const getIndeedInfo = async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
        url: creditUrls.indeed,
        method: 'GET',
      });

      const {
        subscriptionInfo: { contactsRemaining, subscriptionAssigned },
      }: IndeedCommonModelResponse = JSON.parse(response.body);

      return { contactsRemaining, subscriptionAssigned };
    } catch (e) {
      throw e;
    }
  };

  return {
    linkedinStatus: linkedinStatus === 200 ? 'Logged In' : linkedinStatus === null ? 'Unknown' : 'Logged Out',
    linkedinProStatus: linkedinProStatus === 200 ? 'Logged In' : linkedinProStatus === null ? 'Unknown' : 'Logged Out',
    indeedStatus: indeedStatus === 200 ? 'Logged In' : indeedStatus === null ? 'Unknown' : 'Logged Out',
    info,
    sendLinkedinConnectionRequest,
    sendLinkedinMessage,
    existsConversationWithCandidate,
    sendLinkedinProMessage,
    sendIndeedMessage,
    createIndeedProject,
    linkedinCreditBalance,
    indeedCreditBalance,
    indeedProjects,
    indeedJobs,
    hasLinkedinFreeInviteRemaining,
  };
};
