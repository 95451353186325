import { CampaignOverviewResponse, CampaignResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import { routes } from 'config';
import { queryClient } from 'config/queryClient';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetCampaignNewRepliesQuery } from 'shared/hooks/useGetCampaignNewRepliesQuery';
import { QueryKey } from 'types/query';
import { createContext, useContextSelector } from 'use-context-selector';
import { AuthContext } from '../AuthContext';
import { CandidateOutreachModalContext } from '../CandidateOutreachModalContext';
import { initialState } from './CampaignManagerContext.constants';
import { CampaignManagerContextProps, CampaignManagerState } from './CampaignManagerContext.types';
import { useCampaign } from './CampaignManagerContext.useCampaign';
import { useCampaigns } from './CampaignManagerContext.useCampaigns';
import { useFilters } from './CampaignManagerContext.useFilters';
import { useInjectCampaignActions } from './CampaignManagerContext.useInjectCampaignActions';
import { usePathId } from './CampaignManagerContext.usePathId';

export const CampaignManagerContext = createContext(initialState);

export const CampaignManagerContextProvider = ({ children }: CampaignManagerContextProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const filters = useFilters();
  const campaigns = useCampaigns(filters);
  const { id: pathId, tab: initialTab } = usePathId();
  const [selectedCampaignId, setSelectedCampaignId] = useState<number | undefined>();
  const { activeTokenType } = useContext(AuthContext);
  const { campaign: selectedCampaign, isFetching: isLoadingSelectedCampaign } = useCampaign(selectedCampaignId);

  const newRepliesQuery = useGetCampaignNewRepliesQuery();
  const newRepliesAlreadyFetchedRef = useRef<boolean>(false);

  useEffect(() => {
    if (newRepliesAlreadyFetchedRef.current) {
      queryClient.invalidateQueries([QueryKey.campaignManagerContextUseCampaigns], { exact: false, type: 'active' });
      queryClient.invalidateQueries([QueryKey.useCandidateActivityLogQuery], { exact: false, type: 'active' });
      queryClient.invalidateQueries([QueryKey.useCandidateHistoryQuery], { exact: false, type: 'active' });
    }

    if (newRepliesQuery.isFetched && !newRepliesAlreadyFetchedRef.current) {
      newRepliesAlreadyFetchedRef.current = true;
    }
  }, [newRepliesQuery.data, newRepliesQuery.isFetched]);

  const setCandidateOutreachModal = useContextSelector(
    CandidateOutreachModalContext,
    (s) => s.setCandidateOutreachModal,
  );

  const injectCampaignActions = useInjectCampaignActions({
    filters,
    selectedCampaignOverviewId: selectedCampaignId,
    onSelectCampaignOverviewId: setSelectedCampaignId,
  });

  const campaignManager = useCallback(
    (campaign: CampaignOverviewResponse | CampaignResponse) => injectCampaignActions(campaign),
    [injectCampaignActions],
  );

  useEffect(() => {
    if (pathId !== undefined) {
      setCandidateOutreachModal(null);
      setSelectedCampaignId(pathId);
    }
  }, [pathId]);

  useEffect(() => {
    if (selectedCampaignId !== undefined && window.location.href.includes('search')) {
      activeTokenType !== 'impersonate' && campaigns.markCampaignAsRead(selectedCampaignId);

      if (!window.location.href.includes(selectedCampaignId.toString())) {
        const url = routes.search({ campaignId: selectedCampaignId });

        navigate(`${url}`, { replace: true });
      }
    }
  }, [selectedCampaignId]);

  useEffect(() => {
    const isSearchCampaignsRoute = location.pathname.includes('/search/campaigns');

    if (
      !isSearchCampaignsRoute &&
      campaigns.data.length > 0 &&
      selectedCampaign === undefined &&
      pathId === undefined
    ) {
      setSelectedCampaignId(campaigns.data[0].id);
    }

    if (location.pathname === '/search' && selectedCampaignId !== undefined) {
      navigate(routes.search({ campaignId: selectedCampaignId }));
    }
  }, [location, campaigns, campaignManager]);

  const state = useMemo<CampaignManagerState>(
    () => ({
      campaigns,
      selectedCampaign,
      isLoadingSelectedCampaign,
      campaignManager,
      filters,
      setSelectedCampaignId,
      initialTab,
    }),
    [campaigns, selectedCampaign, isLoadingSelectedCampaign, campaignManager, filters, initialTab],
  );

  return <CampaignManagerContext.Provider value={state}>{children}</CampaignManagerContext.Provider>;
};
